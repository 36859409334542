// import 'babel-core/register'
// import 'babel-polyfill'
// import '@sass/editor/index.sass';
import { get } from '@/utils/api'
import Vue from 'vue'

Vue.config.productionTip = false

// window.addEventListener("unhandledrejection", function (event) {
//   console.warn("WARNING: Unhandled promise rejection. Reason: " + event.reason, event);
// });

const createApp = async() => {
  let data
  const defaultData = {
    env: {}
  }
  try {
    data = await get('initial-data')
  } catch (err) {
    console.log('Can`t fetch initial data, check backend configuration')
  }
  if (!data) {
    data = defaultData
  }

  window.__data = data

  await import('./vue-build.js')
}

createApp()

window.tinymceLoaded = false
window.tinymceLoadCallbacks = []
setTimeout(() => {
  const script = document.createElement('script')
  script.src = '/static/tinymce/tinymce.min.js'
  script.onload = () => {
    window.tinymceLoaded = true
    window.tinymceLoadCallbacks.forEach(cb => cb())
  }
  document.body.appendChild(script)
}, 5000)
