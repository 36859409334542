import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const isProd = process.env.NODE_ENV === 'production'
const CookiePath = '/'

export function getToken() {
  // console.log('getToken result: ' + Cookies.get(TokenKey));
  return Cookies.get(TokenKey)
}

const getCookiesOptions = () => {
  const domain = window.__data.env.ADMIN_HOST.replace(/https?:\/\//, '')
  // console.log(domain);
  return {
    ...(isProd ? { domain: domain } : {}),
    path: CookiePath
  }
}

export function setToken(token) {
  const expireDate = new Date(new Date().getTime() + 6 * 60 * 60 * 1000)
  Cookies.set(TokenKey, token, {
    ...getCookiesOptions(),
    secure: isProd,
    expires: expireDate
  })
  // console.log('setTokenOptions', {
  //   ...getCookiesOptions(),
  //   secure: isProd,
  //   expires: expireDate
  // });
}

export function removeToken() {
  const log = {}
  log.before = Cookies.get(TokenKey)
  Cookies.remove(TokenKey)
  log.after1 = Cookies.get(TokenKey)
  Cookies.remove(TokenKey, { path: CookiePath })
  log.after2 = Cookies.get(TokenKey)
  Cookies.remove(TokenKey, getCookiesOptions())
  log.after3 = Cookies.get(TokenKey)
  log.options = getCookiesOptions()
  // console.log('removeTokenLog', log);
}
