import axios from 'axios'
import { Message } from 'element-ui'
import { getToken, setToken } from '@/utils/auth'
import { Notification } from 'element-ui'

// console.log(process.env.VUE_APP_API_BASE)

const serviceConfig = {
  baseURL: process.env.VUE_APP_API_BASE,
  timeout: 30000, // Request timeout
  withCredentials: true,
}

// Create axios instance
const service = axios.create(serviceConfig)

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // Set JWT token
    }

    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  },
)

// response pre-processing
service.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      setToken(response.headers.authorization)
      response.data.token = response.headers.authorization
    }
    // console.log(response.headers, response.headers.authorization, getToken());

    return response.data
  },
  error => {
    let message = error.message
    if (error.response.data && error.response.data.errors) {
      message = error.response.data.errors
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error
    }

    if (Array.isArray(message)) {
      message = message.join(';')
    }
    if (typeof message === 'object') {
      message = Object.values(message).join(';')
    }

    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000,
      showClose: true,
    })
    return Promise.reject(error)
  },
)

export default service

export const get = (url) => {
  return service({
    url: url,
    method: 'get',
  })
}

export const post = (url, data) => {
  return service({
    url: url,
    method: 'post',
    data: data,
  })
}

export const put = (url, data) => {
  return service({
    url: url,
    method: 'put',
    data: data,
  })
}

const catchService = axios.create({
  ...serviceConfig,
  validateStatus: function (/* status */) {
    return true // status >= 200 && status < 300; // default
  },
})

catchService.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // Set JWT token
    }

    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  },
)

export const catchRequest = async (data, cb) => {
  try {
    const response = await catchService(data)
    // console.log(response);
    return response
  } catch (error) {
    if (!cb) {
      // console.error(error);
      let message = error.message
      if (error.response.data && error.response.data.errors) {
        message = error.response.data.errors
      } else if (error.response.data && error.response.data.error) {
        message = error.response.data.error
      }
      if (Array.isArray(message)) {
        message = message.join('; ')
      }
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000,
        showClose: true,
      })
    } else {
      cb(error)
    }
  }
}

export const notifyRequest = async (
  data, successMessage = 'Объект успешно создан', successTitle = 'Успешно!' /* errorTitle = 'Ошибки при отправке' */) => {
  const response = await catchRequest(data)
  if (Math.floor(response.status / 100) === 2) {
    Notification({
      title: successTitle,
      message: successMessage,
      type: 'success',
      duration: 2000,
    })
    return response.data
  }
  if (!response.data.errors && !response.data.error) {
    Message({
      dangerouslyUseHTMLString: true,
      message: 'Ошибка при получении ответа',
      type: 'error',
      duration: 5000,
      showClose: true,
    })
    return response.data
  }
  let errors = []
  if (response.data.errors) {
    if (Array.isArray(response.data.errors)) {
      errors = response.data.errors
    } else if (typeof response.data.errors === 'string') {
      errors = [response.data.errors]
    }
  } else {
    if (Array.isArray(response.data.error)) {
      errors = response.data.error
    } else if (typeof response.data.error === 'string') {
      errors = [response.data.error]
    }
  }
  if (errors.length === 0) {
    Message({
      dangerouslyUseHTMLString: true,
      message: 'Ошибка при получении ответа',
      type: 'error',
      duration: 5000,
      showClose: true,
    })
  } else {
    Message({
      dangerouslyUseHTMLString: true,
      message: '<ul><li>' + errors.join('</li><li>') + '</li></ul>',
      type: 'error',
      duration: 7000,
      showClose: true,
    })
  }
  /*
  Notification({
    title: errorTitle,
    message: response.data.message || response.data.error || 'Неизвестная ошибка',
    type: 'error',
    duration: 5000,
  });
  */
  return response.data
}

export const notifyErrorRequest = async (data /* errorTitle = 'Ошибки при отправке' */) => {
  const response = await catchRequest(data)
  if (Math.floor(response.status / 100) === 2) {
    return response.data
  }
  if (!response.data.errors && !response.data.error) {
    Message({
      dangerouslyUseHTMLString: true,
      message: 'Ошибка при получении ответа',
      type: 'error',
      duration: 5000,
      showClose: true,
    })
    return response.data
  }
  let errors = []
  if (response.data.errors) {
    if (Array.isArray(response.data.errors)) {
      errors = response.data.errors
    } else if (typeof response.data.errors === 'string') {
      errors = [response.data.errors]
    }
  } else {
    if (Array.isArray(response.data.error)) {
      errors = response.data.error
    } else if (typeof response.data.error === 'string') {
      errors = [response.data.error]
    }
  }
  if (errors.length === 0) {
    Message({
      dangerouslyUseHTMLString: true,
      message: 'Ошибка при получении ответа',
      type: 'error',
      duration: 5000,
      showClose: true,
    })
  } else {
    Message({
      dangerouslyUseHTMLString: true,
      message: '<ul><li>' + errors.join('</li><li>') + '</li></ul>',
      type: 'error',
      duration: 7000,
      showClose: true,
    })
  }
  /*
  Notification({
    title: errorTitle,
    message: response.data.message || response.data.error || 'Неизвестная ошибка',
    type: 'error',
    duration: 5000,
  });
  */
  return response.data
}
